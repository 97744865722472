// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import tippy from 'tippy.js';
import '@coreui/coreui';
import 'select2';

import '../styles/main';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

$(document).on('ready turbolinks:load', function() {
  $('[data-tooltip="true"]').each(function() {
    tippy(this, {
      content: $(this).data("message")
    });
  });

  $('select:not(.no-select2)').select2();

  $('.datepicker').attr("autocomplete", "off")
                  .datepicker({ language: $('body').data('locale'),
                                clearBtn: true});
});

import "controllers"
